import { RelatorioVenda } from "src/app/models/relatorio-venda.model";
import { RelatorioService } from "src/app/services/class/relatorio.service";
// default
import { merge, of as observableOf, Subscription } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { LoadingService } from "./../../../services/loading.service";
import { HelperService } from "./../../../services/helper.service";
import { MatDialog } from "@angular/material/dialog";
import { GlobalService } from "./../../../services/global.service";
import { MatPaginator } from "@angular/material/paginator";
import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { MatSelectChange } from "@angular/material";

@Component({
  selector: "app-vendas",
  templateUrl: "./vendas.component.html",
  styleUrls: ["./vendas.component.scss"],
})
export class VendasComponent implements OnInit {
  displayedColumns: string[] = ["1", "2", "3", "4", "5", "6", "7", "actions"];
  // nome, localidade, data, valor
  data: RelatorioVenda[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  filtroTelaData: number = 3;
  dataInicial: Date;
  dataFinal: Date;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    public global: GlobalService,
    public relatorioService: RelatorioService,
    public dialog: MatDialog,
    public helper: HelperService,
    public loadingService: LoadingService,
    public auth: AuthService
  ) {
    this.dataInicial = this.helper.moment().startOf("month").format();
    this.dataFinal = this.helper.moment().endOf("month").format();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.buscar();
  }

  buscar() {
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          // this.paginator.pageIndex + 1, this.paginator.pageSize
          const dataInicial = this.helper.moment(this.dataInicial).format();
          const dataFinal = this.helper.moment(this.dataFinal).format();
          return this.relatorioService.vendas(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.filtroTelaData,
            dataInicial,
            dataFinal
          );
        }),
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.numeroPaginas * this.paginator.pageSize;
          return data.relatorios;
        }),
        catchError((e) => {
          console.log(e);
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe((data) => (this.data = data));
  }

  onDataInicialChange(e: any) {
    this.dataInicial = this.helper.moment(this.dataInicial).format();
    this.buscar();
  }

  onDataFinalChange(e: any) {
    this.dataFinal = this.helper.moment(this.dataFinal).format();
    this.buscar();
  }

  onFiltroTelaDataChange(e: MatSelectChange) {
    this.buscar();
  }

  gerarExcel() {
    const dataInicial = this.helper
      .moment(this.dataInicial)
      .format("MM/DD/YYYY");
    const dataFinal = this.helper.moment(this.dataFinal).format("MM/DD/YYYY");
    this.loadingService.present("Gerando excel...");
    this.relatorioService
      .vendasExcel(
        this.filtroTelaData,
        this.filtroTelaData === 4 ? dataInicial : "",
        this.filtroTelaData === 4 ? dataFinal : ""
      )
      .subscribe(
        (res) => {
          window.open(this.global.appApiUrl + "/" + res, "_blank");
          this.loadingService.dismiss();
        },
        (e) => {
          console.log(e);
          this.loadingService.dismiss();
        }
      );
  }
}
