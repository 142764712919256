import { Documento } from 'src/app/models/documento.model';
import { DocumentoService } from 'src/app/services/class/documento.service';
import { CategoriaDocumentoService } from 'src/app/services/class/categoria-documento.service';
import { CategoriaDocumento } from 'src/app/models/categoria-documento.model';
// default
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatSlideToggleChange } from '@angular/material';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-documentos-editar',
  templateUrl: './documentos-editar.component.html',
  styleUrls: ['./documentos-editar.component.scss']
})
export class DocumentosEditarComponent implements OnInit {

  data: Documento = new Documento();

  searchCategorias: string = '';
  categorias: CategoriaDocumento[] = [];

  file: File;
  progress: any;

  buscarSubscription: Subscription;

  constructor(
    public helper: HelperService,
    public documentoService: DocumentoService,
    public _categoriaDoc: CategoriaDocumentoService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute,
    public auth: AuthService,
    public global: GlobalService
  ) { }
  
  ngOnInit() {
    this.buscarDocumentos();
    this.route.params.subscribe(param => this.buscar(param.id));
  }

  ngOnDestroy() {
    this.buscarSubscription.unsubscribe();
  }

  buscar(id: number) {
    this.buscarSubscription = this.documentoService.getById(id)
      .subscribe((res: Documento) => this.data = res);
  }

  buscarDocumentos() {
    this._categoriaDoc.getSelect(-99, -99)
      .subscribe((res: CategoriaDocumento[]) => this.categorias = res);
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando item...');

    this.documentoService.patch(this.data)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/documento/arquivo?id=${this.data.id}`);
        this.router.navigate(['/documentos']).then(() => {
          this.helper.openSnackBar('Item alterado com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('Alterando arquivo...');
    this.submitImages(this.file, '/documento/arquivo?id=-99').then((res: any) => {
      this.data.imagem = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.documentoService.postFile(file, url, 'file')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeArquivo() {
    this.data.arquivo = '';
  }

}
