import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { CategoriaDocumentoService } from 'src/app/services/class/categoria-documento.service';
import { CategoriaDocumento } from 'src/app/models/categoria-documento.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  page: number = 1;
  itemsPerPage: number = 9;
  categorias: CategoriaDocumento[] = [];

  pages = [
    { name: 'Banners', icon: 'fas fa-images', route: '/banners' },
    { name: 'Sobre', icon: 'fas fa-info', route: '/sobre' },
    { name: 'Linhas do Tempo', icon: 'fas fa-stream', route: '/linhas-do-tempo' },
    { name: 'Produtos', icon: 'fas fa-shapes', route: '/produtos' },
    { name: 'Categorias', icon: 'fas fa-shapes', route: '/categorias' },
    { name: 'Categorias Documentos', icon: 'fas fa-file', route: '/categoria-documentos' },
    { name: 'Documentos', icon: 'fas fa-file', route: '/documentos' },
    { name: 'Parceiros', icon: 'fas fa-user-tie', route: '/parceiros' },
    { name: 'Contato', icon: 'fas fa-phone-alt', route: '/contato' },
    { name: 'Usuários', icon: 'fas fa-users', route: '/usuarios' },
  ]

  constructor(
    public auth: AuthService,
    public _categoriaDoc: CategoriaDocumentoService
  ) { }

  ngOnInit() {
    this.buscar();
  }

  buscar() {
    this._categoriaDoc.getSelect(this.page, this.itemsPerPage)
      .subscribe((res: CategoriaDocumento[]) => this.categorias = res);
  }

}
