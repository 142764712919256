import { LinhaDoTempo } from 'src/app/models/linha-do-tempo.model';
import { LinhaDoTempoService } from 'src/app/services/class/linha-do-tempo.service';
// Default
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-linhas-do-tempo-editar',
  templateUrl: './linhas-do-tempo-editar.component.html',
  styleUrls: ['./linhas-do-tempo-editar.component.scss']
})
export class LinhasDoTempoEditarComponent implements OnInit {

  data: LinhaDoTempo = new LinhaDoTempo();

  buscarSubscription: Subscription;

  constructor(
    public linhaDoTempoSevice: LinhaDoTempoService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => this.buscar(param.id));
  }

  ngOnDestroy() {
    this.buscarSubscription.unsubscribe();
  }

  buscar(id: number) {
    this.buscarSubscription = this.linhaDoTempoSevice.getById(id)
      .subscribe((res: LinhaDoTempo) => this.data = res);
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando...');

    this.linhaDoTempoSevice.patch(this.data)
      .subscribe((res: any) => {
        this.helper.openSnackBar('Item alterado com sucesso.');
        this.router.navigate(['/linhas-do-tempo']);
        this.loadingService.dismiss();
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

}
