import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { Documento } from 'src/app/models/documento.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentoService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, idCategoria: any = '%', filtro?: string): Observable<DocumentosApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/documento/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}&idCategoria=${idCategoria}`;
    } else {
      requestUrl = `/documento/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}&idCategoria=${idCategoria}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/documento/buscar?id=${id}`);
  }

  post(documento: Documento): Observable<any> {
    return this.api.post('/documento/inserir', documento);
  }

  patch(documento: Documento): Observable<any> {
    return this.api.post('/documento/alterar', documento);
  }

  delete(documento: Documento): Observable<any> {
    return this.api.post('/documento/deletar', documento);
  }

  deleteSelected(documentos: Documento[]): Observable<any> {
    return this.api.post('/documento/deletarLista', documentos);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}

export interface DocumentosApi {
  documentos: Documento[];
  numeroPaginas: number;
}
