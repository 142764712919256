import { Injectable } from '@angular/core';
import { AppService } from './../app.service';
import { RelatorioVenda } from 'src/app/models/relatorio-venda.model';
import { RelatorioRegistroContato } from 'src/app/models/relatorio-registro-contato.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RelatorioService {

  constructor(
    public _app: AppService,
  ) { }

  vendas(page: number, count: number, filtroTelaData: number, dataInicial: any = '', dataFinal: any = ''): Observable<RelatoriosApi> {
    return this._app.get(`/relatorio/vendas`, {
      page: page,
      count: count,
      filtroTelaData: filtroTelaData,
      dataInicial: dataInicial,
      dataFinal: dataFinal,
    });
  }

  vendasExcel(filtroTelaData: number, dataInicial: any = '', dataFinal: any = '') {
    return this._app.get(`/relatorio/vendas/excel`, {
      filtroTelaData: filtroTelaData,
      dataInicial: dataInicial,
      dataFinal: dataFinal,
    });
  }

  registroContato(page: number, count: number, filtroTelaData: number, dataInicial: any = '', dataFinal: any = ''): Observable<RelatoriosApi> {
    return this._app.get(`/relatorio/registroContato`, {
      page: page,
      count: count,
      filtroTelaData: filtroTelaData,
      dataInicial: dataInicial,
      dataFinal: dataFinal,
    });
  }

  registroContatoExcel(filtroTelaData: number, dataInicial: any = '', dataFinal: any = '') {
    return this._app.get(`/relatorio/registroContato/excel`, {
      filtroTelaData: filtroTelaData,
      dataInicial: dataInicial,
      dataFinal: dataFinal,
    });
  }

}

export interface RelatoriosApi {
  relatorios: any[];
  numeroPaginas: number;
}
