import { DocumentoService, DocumentosApi } from 'src/app/services/class/documento.service';
import { Documento } from 'src/app/models/documento.model';
// default
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from './../../services/global.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { CategoriaDocumento } from 'src/app/models/categoria-documento.model';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { CategoriaDocumentoService } from 'src/app/services/class/categoria-documento.service';

@Component({
  selector: 'app-documentos-cliente',
  templateUrl: './documentos-cliente.component.html',
  styleUrls: ['./documentos-cliente.component.scss']
})
export class DocumentosClienteComponent implements OnInit {

  displayedColumns: string[] = ['1', '2', 'actions'];
  // nome, descricao, situacao
  data: Documento[] = [];
  categoria: CategoriaDocumento = new CategoriaDocumento();
  html: SafeHtml;

  idCategoria: any = '%';
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  search: string;
  filterSubscription: Subscription;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  selection = new SelectionModel<Documento>(true, []);

  constructor(
    public global: GlobalService,
    public documentoService: DocumentoService,
    public _categoriaDoc: CategoriaDocumentoService,
    public dialog: MatDialog,
    public helper: HelperService,
    public loadingService: LoadingService,
    public auth: AuthService,
    public route: ActivatedRoute,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.idCategoria = param.id;
      this.buscarCategoria(param.id);
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.buscar();
  }

  buscarCategoria(id: number) {
    this._categoriaDoc.getById(id)
      .subscribe((res: CategoriaDocumento) => {
        this.categoria = res;
        this.html = this.sanitizer.bypassSecurityTrustHtml(res.descricao);
      });
  }

  buscar() {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.documentoService.get(this.paginator.pageIndex + 1, this.paginator.pageSize, this.sort.active, this.sort.direction.toLocaleUpperCase(), this.idCategoria, this.search);
        }),
        map(data => {
          console.log(data);
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.selection.clear();
          this.resultsLength = data.numeroPaginas * this.paginator.pageSize;
          return data.documentos;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          this.selection.clear();
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  filter(e) {
    if (this.paginator.pageIndex > 1) {
      this.paginator.pageIndex = 0;
    }
    if (e) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.documentoService.get(this.paginator.pageIndex + 1, this.paginator.pageSize, this.sort.active, this.sort.direction.toLocaleUpperCase(), this.idCategoria, e.toLocaleLowerCase())
        .subscribe((res: DocumentosApi) => {
          this.data = this.paginator.pageIndex == 0 ? res.documentos : this.data.concat(res.documentos);
        });
    } else {
      this.buscar();
    }
  }

  clearFilter() {
    this.search = '';
    this.buscar();
  }

  download(arquivo: string) {
    window.open(this.global.imageUrl + arquivo, '_blank');
  }

}
