import { Injectable } from '@angular/core';
import { LinhaDoTempo } from 'src/app/models/linha-do-tempo.model';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LinhaDoTempoService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<LinhasDoTempoApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/linhaDoTempo/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/linhaDoTempo/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/linhaDoTempo/buscar?id=${id}`);
  }

  post(linhaDoTempo: LinhaDoTempo): Observable<any> {
    return this.api.post('/linhaDoTempo/inserir', linhaDoTempo);
  }

  patch(linhaDoTempo: LinhaDoTempo): Observable<any> {
    return this.api.post('/linhaDoTempo/alterar', linhaDoTempo);
  }

  delete(linhaDoTempo: LinhaDoTempo): Observable<any> {
    return this.api.post('/linhaDoTempo/deletar', linhaDoTempo);
  }

  deleteSelected(linhasDoTempo: LinhaDoTempo[]): Observable<any> {
    return this.api.post('/linhaDoTempo/deletarLista', linhasDoTempo);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}

export interface LinhasDoTempoApi {
  linhasDoTempo: LinhaDoTempo[];
  numeroPaginas: number;
}

