import { Categoria } from './categoria.model';
import { Subcategoria } from './subcategoria.model';

export class Produto {

    id: number;
    nome: string = '';
    situacao: string = 'A';
    categoria: Categoria;
    subcategoria: Subcategoria;
    aplicacao: string = '';
    caracteristica: string = '';
    imagem: string = '';
    ordem: number = 99;
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}