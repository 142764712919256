import { LoadingService } from './../../services/loading.service';
import { HelperService, ViaCEP } from './../../services/helper.service';
import { NgForm } from '@angular/forms';
import { ContatoService } from './../../services/class/contato.service';
import { ContatoGeral } from './../../models/contato-geral.model';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit {

  phoneMask: string = '(00) 0000-00009';

  data: ContatoGeral = new ContatoGeral();
  buscarSubscription: Subscription;

  loading: boolean = false;

  hide: boolean = true;

  constructor(
    public contatoService: ContatoService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public dialog: MatDialog,
    public auth: AuthService
  ) { }

  ngOnInit() {
    this.buscar();
  }

  ngOnDestroy() {
    this.buscarSubscription.unsubscribe();
  }

  submit(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando cadastro geral...');

    this.contatoService.alterarGeral(this.data)
      .subscribe((res: any) => {
        this.auth.setTokenApp(btoa(this.data.usuarioApp + ':' + this.data.senhaApp));
        this.helper.openSnackBar('Contato geral alterado com sucesso.');
        this.loadingService.dismiss();
      }, e => this.loadingService.dismiss());

  }

  buscar() {
    this.loading = true;
    this.buscarSubscription = this.contatoService.buscarGeral()
      .subscribe((res: ContatoGeral) => {
        this.data = res;
        this.auth.setTokenApp(btoa(res.usuarioApp + ':' + res.senhaApp));
        this.loading = false;
      }, e => this.loading = false);
  }

  @ViewChild('numeroEnderecoInput', { static: false }) numeroEnderecoInput: ElementRef;
  onCepBlur(e) {
    if (this.data.cep.length == 8) {
      this.helper.viaCep(this.data.cep).subscribe((res: ViaCEP) => {
        this.data.bairro = res.bairro;
        this.data.uf = res.uf;
        this.data.cidade = res.localidade;
        this.data.rua = res.logradouro;
        this.numeroEnderecoInput.nativeElement.focus();
      });
    }
  }

  changePhoneMask(event) {
    let val = event;
    if (!val) {
      return;
    }
    if (val.replace(/\D/g, '').substring(0, 4) == "0800") {
      this.phoneMask = '0000 000 0000';
    } else {
      if (val.replace(/\D/g, '').length === 11) {
        this.phoneMask = '(00) 0 0000-0000';
      } else {
        this.phoneMask = '(00) 0000-00009';
      }
    }
  }

}
