import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class IsAdminLoggedInGuard implements CanActivate {

  constructor(
    public auth: AuthService,
    public router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    if (this.auth.isLoggedIn() && this.auth.user.tipo == 'A') {
      return true;
    } else if (this.auth.isLoggedIn()) {
      this.router.navigate(['/'])
      return true
    }

    this.auth.redirectUrl = state.url;
    this.router.navigate(['/login']);
    return false;

  }

}
