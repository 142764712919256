export class Parceiro {

    id: number;
    nome: string = '';
    link: string = '';
    imagem: string = '';
    ordem: number = 99;
    [x: string]: any;
    
    constructor(obj?) {
        Object.assign(this);
    }
}