import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  appApiUrl: string = "https://apiapp.lubsuldistribuidor.com.br";
  apiUrl: string = "https://api.lubsuldistribuidor.com.br";
  imageUrl: string = "https://api.lubsuldistribuidor.com.br/";
  menuOpen: boolean = false;

  constructor() {}

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }
}
