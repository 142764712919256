import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Subcategoria } from 'src/app/models/subcategoria.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatSlideToggleChange } from '@angular/material';
import { SubcategoriaService } from 'src/app/services/class/subcategoria.service';
import { LoadingService } from 'src/app/services/loading.service';
import { HttpProgressEvent } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService } from 'src/app/services/helper.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-modal-subcategoria',
  templateUrl: './modal-subcategoria.component.html',
  styleUrls: ['./modal-subcategoria.component.scss']
})
export class ModalSubcategoriaComponent implements OnInit {

  subcategoria: Subcategoria = new Subcategoria();

  file: File;
  progress: number = 0;

  constructor(
    public dialogRef: MatDialogRef<ModalSubcategoriaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public subcategoriaService: SubcategoriaService,
    public loadingService: LoadingService,
    public global: GlobalService,
    public helper: HelperService
  ) { }

  ngOnInit() {
    if (this.data.obj) {
      this.buscar(this.data.obj.id);
    } else {
      this.subcategoria.categoria.id = this.data.idCategoria;
    }
  }

  buscar(id: number) {
    this.loadingService.present('Buscando...');
    this.subcategoriaService.getById(id).subscribe((res: Subcategoria) => {
      this.subcategoria = res;
      this.loadingService.dismiss();
    }, e => this.loadingService.dismiss());
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha todos os campos requiridos.');
      return;
    }
    this.dialogRef.close(this.subcategoria);
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('Alterando imagem...');
    this.submitImages(this.file, `/subcategoria/imagem?id=${this.subcategoria.id ? this.subcategoria.id : -99}`).then((res: any) => {
      this.subcategoria.imagem = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.subcategoriaService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImage() {
    this.subcategoria.imagem = '';
  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.subcategoria.situacao = 'A' : this.subcategoria.situacao = 'I';
  }
}
