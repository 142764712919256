import { Categoria } from 'src/app/models/categoria.model';
import { CategoriaService } from 'src/app/services/class/categoria.service';
// default
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatSlideToggleChange } from '@angular/material';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-categorias-editar',
  templateUrl: './categorias-editar.component.html',
  styleUrls: ['./categorias-editar.component.scss']
})
export class CategoriasEditarComponent implements OnInit {

  data: Categoria = new Categoria();
  req: Subscription;

  file: File;
  progress: any;

  constructor(
    public helper: HelperService,
    public categoriaService: CategoriaService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute,
    public auth: AuthService,
    public global: GlobalService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => this.buscar(param.id));
  }

  buscar(id: number) {
    this.req = this.categoriaService.getById(id)
      .subscribe((res: Categoria) => this.data = res);
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando item...');
    this.data.slug = this.helper.convertToSlug(this.data.nome);

    this.categoriaService.patch(this.data)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/categoria/imagem?id=${this.data.id}`);
        this.router.navigate(['/categorias']).then(() => {
          this.helper.openSnackBar('Item alterado com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('Alterando imagem...');
    this.submitImages(this.file, '/categoria/imagem?id=-99').then((res: any) => {
      this.data.imagem = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.categoriaService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImage() {
    this.data.imagem = '';
  }
}
