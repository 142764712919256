import { IsLoggedInGuard } from './guards/is-logged-in.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// pages
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { UsuariosEditarComponent } from './pages/usuarios-editar/usuarios-editar.component';
import { UsuariosCriarComponent } from './pages/usuarios-criar/usuarios-criar.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { BannersComponent } from './pages/banners/banners.component';
import { BannersEditarComponent } from './pages/banners-editar/banners-editar.component';
import { BannersCriarComponent } from './pages/banners-criar/banners-criar.component';
import { LinhasDoTempoComponent } from './pages/linhas-do-tempo/linhas-do-tempo.component';
import { LinhasDoTempoCriarComponent } from './pages/linhas-do-tempo-criar/linhas-do-tempo-criar.component';
import { LinhasDoTempoEditarComponent } from './pages/linhas-do-tempo-editar/linhas-do-tempo-editar.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { ParceirosComponent } from './pages/parceiros/parceiros.component';
import { ParceirosCriarComponent } from './pages/parceiros-criar/parceiros-criar.component';
import { ParceirosEditarComponent } from './pages/parceiros-editar/parceiros-editar.component';
import { CategoriasComponent } from './pages/categorias/categorias.component';
import { CategoriasCriarComponent } from './pages/categorias-criar/categorias-criar.component';
import { CategoriasEditarComponent } from './pages/categorias-editar/categorias-editar.component';
import { CategoriaDocumentosComponent } from './pages/categoria-documentos/categoria-documentos.component';
import { CategoriaDocumentosCriarComponent } from './pages/categoria-documentos-criar/categoria-documentos-criar.component';
import { CategoriaDocumentosEditarComponent } from './pages/categoria-documentos-editar/categoria-documentos-editar.component';
import { DocumentosComponent } from './pages/documentos/documentos.component';
import { DocumentosCriarComponent } from './pages/documentos-criar/documentos-criar.component';
import { DocumentosEditarComponent } from './pages/documentos-editar/documentos-editar.component';
import { IsAdminLoggedInGuard } from './guards/is-admin-logged-in.guard';
import { DocumentosClienteComponent } from './pages/documentos-cliente/documentos-cliente.component';
import { VendasComponent } from './pages/relatorios/vendas/vendas.component';
import { RegistrosContatoComponent } from './pages/relatorios/registros-contato/registros-contato.component';
import { ProdutosComponent } from './pages/produtos/produtos.component';
import { ProdutosEditarComponent } from './pages/produtos-editar/produtos-editar.component';
import { ProdutosCriarComponent } from './pages/produtos-criar/produtos-criar.component';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: '', component: HomeComponent, canActivate: [IsLoggedInGuard] },
  { path: 'contato', component: ContatoComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'sobre', component: SobreComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'banners', component: BannersComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'banners/adicionar', component: BannersCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'banners/editar/:id', component: BannersEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'linhas-do-tempo', component: LinhasDoTempoComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'linhas-do-tempo/adicionar', component: LinhasDoTempoCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'linhas-do-tempo/editar/:id', component: LinhasDoTempoEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'parceiros', component: ParceirosComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'parceiros/adicionar', component: ParceirosCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'parceiros/editar/:id', component: ParceirosEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'categorias', component: CategoriasComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'categorias/adicionar', component: CategoriasCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'categorias/editar/:id', component: CategoriasEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'categoria-documentos', component: CategoriaDocumentosComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'categoria-documentos/adicionar', component: CategoriaDocumentosCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'categoria-documentos/editar/:id', component: CategoriaDocumentosEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'produtos', component: ProdutosComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'produtos/adicionar', component: ProdutosCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'produtos/editar/:id', component: ProdutosEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'c/documentos/:id', component: DocumentosClienteComponent, canActivate: [IsLoggedInGuard] },
  { path: 'documentos', component: DocumentosComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'documentos/adicionar', component: DocumentosCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'documentos/editar/:id', component: DocumentosEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'usuarios', component: UsuariosComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'usuarios/adicionar', component: UsuariosCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'usuarios/editar/:id', component: UsuariosEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'r/vendas', component: VendasComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'r/registros-contato', component: RegistrosContatoComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
