import { CategoriaDocumento } from './categoria-documento.model';

export class Documento {

    id: number;
    nome: string = '';
    arquivo: string = '';
    categoria: CategoriaDocumento;
    ordem: number = 99;
    situacao: string = 'A';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}