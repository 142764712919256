import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { Subcategoria } from 'src/app/models/subcategoria.model';

@Injectable({
  providedIn: 'root'
})
export class SubcategoriaService {

  constructor(
    public api: ApiService
  ) { }

  get(idCategoria: number): Observable<Subcategoria[]> {
    return this.api.get(`/subcategoria/buscar?idCategoria=${idCategoria}`);
  }

  getById(id): Observable<any> {
    return this.api.get(`/subcategoria/buscar?id=${id}`);
  }

  getSelect(idCategoria: number) {
    return this.api.get(`/select/subcategoria/buscar?idCategoria=${idCategoria}`)
  }

  post(subcategoria: Subcategoria): Observable<any> {
    return this.api.post('/subcategoria/inserir', subcategoria);
  }

  patch(subcategoria: Subcategoria): Observable<any> {
    return this.api.post('/subcategoria/alterar', subcategoria);
  }

  delete(subcategoria: Subcategoria): Observable<any> {
    return this.api.post('/subcategoria/deletar', subcategoria);
  }

  deleteSelected(subcategorias: Subcategoria[]): Observable<any> {
    return this.api.post('/subcategoria/deletarLista', subcategorias);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  compareFn(v1: Subcategoria, v2: Subcategoria): boolean {
    return v1 && v2 ? v1.id === v2.id : v1 === v2
  }
}
