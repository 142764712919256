import { GlobalService } from './../../services/global.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoriaDocumentoService } from 'src/app/services/class/categoria-documento.service';
import { CategoriaDocumento } from 'src/app/models/categoria-documento.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  page: number = 1;
  itemsPerPage: number = 12;

  categorias: CategoriaDocumento[] = [];

  search: string = '';
  pages = [
    { name: 'Banners', icon: 'fas fa-images', route: '/banners' },
    { name: 'Sobre', icon: 'fas fa-info', route: '/sobre' },
    { name: 'Linhas do Tempo', icon: 'fas fa-stream', route: '/linhas-do-tempo' },
    { name: 'Produtos', icon: 'fas fa-boxes', route: '/produtos' },
    { name: 'Categorias', icon: 'fas fa-shapes', route: '/categorias' },
    { name: 'Categorias Documentos', icon: 'fas fa-file', route: '/categoria-documentos' },
    { name: 'Documentos', icon: 'fas fa-file', route: '/documentos' },
    { name: 'Parceiros', icon: 'fas fa-user-tie', route: '/parceiros' },
    { name: 'Contato', icon: 'fas fa-phone-alt', route: '/contato' },
    { name: 'Usuários', icon: 'fas fa-users', route: '/usuarios' },
    { name: 'Vendas', icon: 'fas fa-chart-line', route: '/r/vendas' },
    { name: 'Registros de Contato', icon: 'fas fa-chart-line', route: '/r/registros-contato' },
  ]

  constructor(
    public auth: AuthService,
    public global: GlobalService,
    public router: Router,
    public _categoriaDoc: CategoriaDocumentoService,
  ) { }

  ngOnInit() {
    this.buscar();
  }

  goTo(page: string) {
    this.router.navigate([page]).then(() => {
      this.global.menuOpen = false;
    });
  }

  buscar() {
    this._categoriaDoc.getSelect(this.page, this.itemsPerPage)
      .subscribe((res: CategoriaDocumento[]) => this.categorias = res);
  }

  logout() {
    this.router.navigate(['/login']).then(() => {
      this.global.menuOpen = false;
      this.auth.logout();
    })
  }

}
