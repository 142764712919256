import { CategoriaDocumento } from 'src/app/models/categoria-documento.model';
import { CategoriaDocumentoService } from 'src/app/services/class/categoria-documento.service';
// Default
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material';

@Component({
  selector: 'app-categoria-documentos-criar',
  templateUrl: './categoria-documentos-criar.component.html',
  styleUrls: ['./categoria-documentos-criar.component.scss']
})
export class CategoriaDocumentosCriarComponent implements OnInit {

  data: CategoriaDocumento = new CategoriaDocumento();

  constructor(
    public _categoriaDoc: CategoriaDocumentoService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router
  ) { }

  ngOnInit() {
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo...');

    this._categoriaDoc.post(this.data)
      .subscribe((res: any) => {
        this.helper.openSnackBar('Item inserido com sucesso.');
        this.router.navigate(['/categoria-documentos']);
        this.loadingService.dismiss();
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }

}
