import { CategoriaDocumento } from 'src/app/models/categoria-documento.model';
import { CategoriaDocumentoService } from 'src/app/services/class/categoria-documento.service';
// Default
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material';

@Component({
  selector: 'app-categoria-documentos-editar',
  templateUrl: './categoria-documentos-editar.component.html',
  styleUrls: ['./categoria-documentos-editar.component.scss']
})
export class CategoriaDocumentosEditarComponent implements OnInit {

  data: CategoriaDocumento = new CategoriaDocumento();

  buscarSubscription: Subscription;

  constructor(
    public _categoriaDoc: CategoriaDocumentoService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => this.buscar(param.id));
  }

  ngOnDestroy() {
    this.buscarSubscription.unsubscribe();
  }

  buscar(id: number) {
    this.buscarSubscription = this._categoriaDoc.getById(id)
      .subscribe((res: CategoriaDocumento) => this.data = res);
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando...');

    this._categoriaDoc.patch(this.data)
      .subscribe((res: any) => {
        this.helper.openSnackBar('Item alterado com sucesso.');
        this.router.navigate(['/categoria-documentos']);
        this.loadingService.dismiss();
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }

}
