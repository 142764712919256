import { NgModule } from '@angular/core';
// Material
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { getBrazilianPaginatorIntl } from './brazilian-paginator-intl';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material';
// import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatTabsModule } from '@angular/material/tabs';
// imports
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from "ngx-currency";
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// components
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { LoadingComponent } from './components/loading/loading.component';
import { HeaderComponent } from './components/header/header.component';
import { ModalResetPasswordComponent } from './components/modal-reset-password/modal-reset-password.component';
// pages
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { UsuariosEditarComponent } from './pages/usuarios-editar/usuarios-editar.component';
import { UsuariosCriarComponent } from './pages/usuarios-criar/usuarios-criar.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { ContatoComponent } from './pages/contato/contato.component';
// locale
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { FilterPipe } from './pipes/filter.pipe';
import { BannersComponent } from './pages/banners/banners.component';
import { BannersCriarComponent } from './pages/banners-criar/banners-criar.component';
import { BannersEditarComponent } from './pages/banners-editar/banners-editar.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { LinhasDoTempoComponent } from './pages/linhas-do-tempo/linhas-do-tempo.component';
import { LinhasDoTempoCriarComponent } from './pages/linhas-do-tempo-criar/linhas-do-tempo-criar.component';
import { LinhasDoTempoEditarComponent } from './pages/linhas-do-tempo-editar/linhas-do-tempo-editar.component';
import { ParceirosComponent } from './pages/parceiros/parceiros.component';
import { ParceirosCriarComponent } from './pages/parceiros-criar/parceiros-criar.component';
import { ParceirosEditarComponent } from './pages/parceiros-editar/parceiros-editar.component';
import { CategoriasComponent } from './pages/categorias/categorias.component';
import { CategoriasCriarComponent } from './pages/categorias-criar/categorias-criar.component';
import { CategoriasEditarComponent } from './pages/categorias-editar/categorias-editar.component';
import { SubcategoriasComponent } from './pages/subcategorias/subcategorias.component';
import { ModalSubcategoriaComponent } from './components/modal-subcategoria/modal-subcategoria.component';
import { CategoriaDocumentosComponent } from './pages/categoria-documentos/categoria-documentos.component';
import { CategoriaDocumentosCriarComponent } from './pages/categoria-documentos-criar/categoria-documentos-criar.component';
import { CategoriaDocumentosEditarComponent } from './pages/categoria-documentos-editar/categoria-documentos-editar.component';
import { DocumentosComponent } from './pages/documentos/documentos.component';
import { DocumentosCriarComponent } from './pages/documentos-criar/documentos-criar.component';
import { DocumentosEditarComponent } from './pages/documentos-editar/documentos-editar.component';
import { DocumentosClienteComponent } from './pages/documentos-cliente/documentos-cliente.component';
import { VendasComponent } from './pages/relatorios/vendas/vendas.component';
import { RegistrosContatoComponent } from './pages/relatorios/registros-contato/registros-contato.component';
import { ProdutosComponent } from './pages/produtos/produtos.component';
import { ProdutosCriarComponent } from './pages/produtos-criar/produtos-criar.component';
import { ProdutosEditarComponent } from './pages/produtos-editar/produtos-editar.component';
registerLocaleData(localePt);

export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ModalResetPasswordComponent,
    DialogComponent,
    LoadingComponent,
    // pages
    HomeComponent,
    LoginComponent,
    SobreComponent,
    ContatoComponent,
    UsuariosComponent,
    UsuariosCriarComponent,
    UsuariosEditarComponent,
    BannersComponent,
    BannersCriarComponent,
    BannersEditarComponent,
    LinhasDoTempoComponent,
    LinhasDoTempoCriarComponent,
    LinhasDoTempoEditarComponent,
    ParceirosComponent,
    ParceirosCriarComponent,
    ParceirosEditarComponent,
    CategoriasComponent,
    CategoriasCriarComponent,
    CategoriasEditarComponent,
    SubcategoriasComponent,
    ModalSubcategoriaComponent,
    CategoriaDocumentosComponent,
    CategoriaDocumentosCriarComponent,
    CategoriaDocumentosEditarComponent,
    SubcategoriasComponent,
    // pipes
    FilterPipe,
    DocumentosComponent,
    DocumentosCriarComponent,
    DocumentosEditarComponent,
    DocumentosClienteComponent,
    VendasComponent,
    RegistrosContatoComponent,
    ProdutosComponent,
    ProdutosCriarComponent,
    ProdutosEditarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxSummernoteModule,
    NgxMatSelectSearchModule,
    // Material
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    NgxDropzoneModule,
  ],
  entryComponents: [
    DialogComponent,
    ModalResetPasswordComponent,
    ModalSubcategoriaComponent
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getBrazilianPaginatorIntl() },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
