import { LinhaDoTempo } from 'src/app/models/linha-do-tempo.model';
import { LinhaDoTempoService } from 'src/app/services/class/linha-do-tempo.service';
// Default
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-linhas-do-tempo-criar',
  templateUrl: './linhas-do-tempo-criar.component.html',
  styleUrls: ['./linhas-do-tempo-criar.component.scss']
})
export class LinhasDoTempoCriarComponent implements OnInit {

  data: LinhaDoTempo = new LinhaDoTempo();

  constructor(
    public linhaDoTempoSevice: LinhaDoTempoService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router
  ) { }

  ngOnInit() {
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo...');

    this.linhaDoTempoSevice.post(this.data)
      .subscribe((res: any) => {
        this.helper.openSnackBar('Item inserido com sucesso.');
        this.router.navigate(['/linhas-do-tempo']);
        this.loadingService.dismiss();
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

}
